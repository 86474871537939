<template>
    <div>
        <main-layout>
            <div class="row">
                <div class="col-12">
                    <div class="statbox widget box box-shadow">
                        <div class="widget-header">
                            <div class="row">
                                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                    <h4>Place new order</h4>
                                </div>
                            </div>
                        </div>
                        <div class="widget-content widget-content-area">
                            <validation-errors :errors="validationErrors"></validation-errors>
                            <div v-if="customerSubscription != null && customerSubscription != ''">
                                <div v-if="customerSubscription.exceeded == false">
                                    <p>You still have an active subscription, do you want to pay using your subscription?</p>
                                    
                                    <div class="n-chk">
                                        <label class="new-control new-radio radio-warning">
                                            <input type="checkbox" name="pay-with-sub" class="new-control-input" v-model="payWithSub">
                                            <span class="new-control-indicator"></span> Yes, I want to pay using my subscription
                                        </label>
                                    </div>
                                    <div class="alert alert-info" v-if="payWithSub == true">You will have {{ customerSubscription.order_limit - (customerSubscription.order_count + deliveries.length) }} orders left</div>
                                </div>
                            </div>
                            <div id="toggleAccordion">
                                <div class="card">
                                    <div class="card-header bg-secondary-light">
                                        <section class="p-3">
                                            <i class="fa fa-cart-plus"></i> Add pickup details
                                        </section>
                                    </div>

                                    <div id="" class="" aria-labelledby="...">
                                        <div class="card-body">
                                            <div class="" v-if="payWithSub == false">
                                                <div class="form-group">
                                                    <label for="Pickup Address">Address *</label>
                                                    <input type="text" class="form-control" name="pickupAddress" v-model="pickupLocation.full_address" placeholder="Pickup Address">
                                                    <div class="help-text text-danger font-weight-bold" v-if="pickupLocation.address_not_found === true">
                                                        Your pickup location could not be found. Please try another location.
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col col-sm-6">
                                                        <label for="State">State</label>
                                                        <multiselect value="id" v-model="pickupLocation.state" :options="pickupStatesList" track-by="id" label="name" @select="(selected, id) => {pickupLocation.state_id = selected.id; getPickupCities(selected, id)}"></multiselect>
                                                    </div>
                                                    <div class="form-group col col-sm-6">
                                                        <label for="City">City</label>
                                                        <multiselect value="id" v-model="pickupLocation.lga" :options="pickupCitiesList" track-by="id" label="name" @select="(selected, id) => {pickupLocation.lga_id = selected.id}"></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="form-group">
                                                <label for="Picup Address">Choose Address *</label>
                                                <select name="pickupAddress" id="pickupAddress" class="form-control" @change="onSelectSubscriptionAddress($event)">
                                                    <option value="">Select pickup address</option>
                                                    <option :value="index" v-for="(l, index) in customerSubscription.user_locations" :key="l.id">{{ l.address }}</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label for="Sender Name">Name *</label>
                                                <input type="text" name="senderName" id="senderName" class="form-control" placeholder="Sender Name" v-model="senderName" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="Sender Email">Email *</label>
                                                <input type="email" name="senderEmail" id="senderEmail" class="form-control" placeholder="Sender Email" v-model="senderEmail" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="Sender Phone number">Phone number *</label>
                                                <input type="tel" name="senderPhone" id="senderPhone" class="form-control" placeholder="Phone Number" v-model="senderPhone" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="Item description">Item description *</label>
                                                <input type="text" name="item_description" id="item_description" class="form-control" placeholder="What's the item you're delivering?" v-model="itemDescription" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="Extra notes">Extra details about the pickup</label>
                                                <textarea name="pickupExtra" id="extraNote" class="form-control" placeholder="Is there anything else we should know about the pickup?" v-model="pickupExtraInfo"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <section class="p-3">
                                            <i class="fa fa-truck"></i> Deliveries
                                        </section>
                                    </div>
                                    <div>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label for="File upload">Upload excel file</label>
                                                <input type="file" name="file-upload" class="form-control" @change="chooseFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                            </div>
                                            <p>
                                                The bulk template feature allows send an order to multiple delivery locations by uploading via an existing excel sheet.
                                                <br>
                                                <a href="/bulk_upload_template/ftd_bulk_template.xlsx" target="_blank" download>Click here to download the excel template</a>, then fill for upload.
                                            </p>
                                            <div class="form-group">
                                                <button class="btn btn-warning float-right mt-3 mb-3" @click="createOrder">Confirm order</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main-layout>
    </div>
</template>
<script>
import { useToast } from 'vue-toastification';
import appconfig from '../../appconfig';
import Auth from '../../helpers/auth';

import MainLayout from "../../layouts/MainLayout";
import instance from '../../presenter/api/network';
import { loadScripts } from '../../utils';
import ValidationErrors from '../../components/ValidationErrors.vue';
import Multiselect from '@suadelabs/vue3-multiselect'
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css'
import $ from "jquery"
export default {
    components: {MainLayout, ValidationErrors, Multiselect},
    mounted(){
        
        this.getSubscriptionInfo();
        this.getCountries().then(() => this.getPickupStates()).catch((err) => console.log(err));
        loadScripts([
            "assets/vendor/js/components/ui-accordions.js",
            "assets/plugins/autocomplete/jquery.autocomplete.js",
            // `https://maps.googleapis.com/maps/api/js?key=${appconfig.googleMapsApiKey}&callback=mapCallback&libraries=places`
        ])        
    },
    data(){
        return {
            pickupLocation: {
                full_address: "",
                country: "",
                state: "",
                lga: "",
                street: "",
                country_id: "",
                state_id: "",
                lga_id: "",
                google_address_selected: false,
                address_not_found: false,
            },
            senderName: Auth.user().first_name + " " + Auth.user().last_name,
            senderEmail: Auth.user().email,
            senderPhone: Auth.user().phone,
            pickupExtraInfo: "",
            pickupLandmark: "",
            deliveries: [],
            deliveryTemplate: {
                full_address: "",
                country: "",
                state: "",
                lga: "",
                street: "",
                country_id: "",
                state_id: "",
                lga_id: "",
                google_address_selected: false,
                address_not_found: false,
                recipientName: "",
                recipientEmail: "",
                recipientPhone: "",
                deliveryExtraInfo: "",
                deliveryStatesList: [],
                deliveryCitiesList: []
            },
            uploadedFile: null,
            // recipientName: "",
            // recipientEmail: "",
            // recipientPhone: "",
            itemWeight: "",
            totalCost: 0,
            estimateFound: true,
            itemDescription: "",
            completedOrderId: null,
            orderCompleted: false,
            estimateAvailable: false,
            validationErrors: "",
            customerSubscription: null,
            payWithSub: false,
            selectedSubscriptionLocation: "",
            countriesList: [],
            defaultCountry: null,
            pickupStatesList: [],
            pickupCitiesList: [],
            // deliveryStatesList: [],
            // deliveryCitiesList: []
        }
    },
    methods: {
        getCountries(){
            return instance.get("getCountryData").then(res => {
                    this.countriesList = res.data.data;
                    let countryNigeria = this.countriesList.find(item => {
                        return item.name.toLowerCase() == "nigeria";
                    });
                    this.defaultCountry = this.pickupLocation.country = this.deliveryTemplate.country = countryNigeria;
                    this.pickupLocation.country_id = this.deliveryTemplate.country_id = countryNigeria.id;
                    
                }).catch(err => {
                    console.log(err);
                })
        },
        getPickupStates(){
            
            instance.get(`getStateData?country_id=${this.defaultCountry.id}`).then(res => {
                this.pickupStatesList = res.data.data;
                this.deliveryTemplate.deliveryStatesList = res.data.data;
                this.deliveries.forEach((d, index) => {
                    this.deliveries[index].deliveryStatesList = res.data.data;
                })
            })
        },
        getPickupCities(selectedOption, id){
            
            instance.get(`getCityData?state_id=${selectedOption.id}`).then(res => {
                this.pickupCitiesList = res.data.data;
            })
        },
        getDeliveryStates(selectedOption, id, index){
            instance.get(`getStateData?country_id=${selectedOption.id}`).then(res => {
                this.deliveries[index].deliveryStatesList = res.data.data;
            })
        },
        getDeliveryCities(selectedOption, id, index){
            instance.get(`getCityData?state_id=${selectedOption.id}`).then(res => {
                this.deliveries[index].deliveryCitiesList = res.data.data;
            })
        },
        getSubscriptionInfo(){
            this.$root.startRequestLoader();
            instance.get("customer/user-subscription-information").then(res => {
                this.customerSubscription = res.data.data.current_subscription_information;
            }).catch(err => {

            }).finally(() => {
                this.$root.endRequestLoader();
            })
        },
        onSelectSubscriptionAddress(e){
            let target = e.target;
            if (target.value == ""){
                alert("You need to select an address");
                return;
            }
            const location = this.customerSubscription.user_locations[target.value];
            this.pickupLocation.full_address = location.address;
            this.pickupLocation.lga_id = location.city_id;
            this.pickupLocation.state_id = location.state_id;
            this.pickupLocation.country_id = location.country_id;
            this.selectedSubscriptionLocation = location;
        },
        chooseFile(event) {
            this.uploadedFile = event.target.files[0];
        },
        
        createOrder(){
            this.$root.startRequestLoader();
            
            let data = {
                user_id: Auth.user().id,
                pickup_name: this.senderName,
                pickup_email: this.senderEmail,
                pickup_address: this.pickupLocation.full_address,
                pickup_city_id: this.pickupLocation.lga.id,
                pickup_state_id: this.pickupLocation.state.id,
                pickup_country_id: this.pickupLocation.country.id,
                pickup_phone: this.senderPhone,
                item_description: this.itemDescription,
                pickup_extra_information: this.pickupExtraInfo,
            }

            let formData = new FormData();
            formData.append('excel_file', this.uploadedFile);
            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const element = data[key];
                    formData.append(key, data[key]);
                }
            }
            if (this.payWithSub == true){
                data.use_subscription = true;
                data.user_subscription_location_id = this.selectedSubscriptionLocation.id;
            }
            instance.post("customer/create-order", formData, { headers: { "content-type": "multipart/form-data" } }).then(res => {
                
                let orders = [];
                orders = res.data.data;
                let orderIds = orders.map(o => o.orderID);
                this.confirmOrder(orderIds);
            }).catch(err => {
                if (err.response != undefined){
                    useToast().error(err.response.data.message ?? "Unable to create your order at the moment");
                    if (err.response.status == 400) {
                        this.validationErrors = err.response.data.data;
                    }
                    
                }else{
                    useToast().error("An unknown error occured, please refresh and try again");
                }
                this.$root.endRequestLoader();
            })
        },
        confirmOrder(orderId){
            let postData = {
                orderID: orderId,
                user_id: Auth.user().id
            }
            instance.post("customer/confirm-order", postData).then(res => {
                useToast().success("Your order has been placed successfully");
                this.orderCompleted = true;
                localStorage.removeItem("orderPrePlacementDetails");
                this.$root.endRequestLoader();
                // this.$router.push(`/orders/${orderId}/view`);
                this.$router.push(`/orders/`);

            }).catch(err => {
                this.$root.endRequestLoader();
                if (err.response != undefined){
                    useToast().error(err.response.data?.message ?? "There was an error placing your order, please try again");
                }
                
            })
        }
    }
}
</script>
<style scoped>
@import url("../../assets/lib/vendor/css/components/tabs-accordian/custom-accordions.css");
@import url("../../assets/lib/plugins/autocomplete/autocomplete.css");
@import url("../../assets/lib/vendor/css/forms/theme-checkbox-radio.css");
@import url("../../assets/lib/plugins/select2/select2.min.css");
</style>